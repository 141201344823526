












































import { Component, Vue, Prop } from "vue-property-decorator";
import { INavItem } from "../navBar/index.d";

@Component({
  components: {},
})
export default class NavBar extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly navItemsUS?: INavItem[];
  @Prop({ type: Array, default: () => [] }) readonly navItemsCN?: INavItem[];

  private selectedItem = 1;
  private items = [
    {
      title: "New Releases",
      text: `It's New Release Friday`,
      subtext: "Newly released songs. Updated daily.",
      img: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
    },
    {
      title: "Rock",
      text: "Greatest Rock Hits",
      subtext: "Lose yourself in rock tunes.",
      img: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
    },
    {
      title: "Mellow Moods",
      text: "Ambient Bass",
      subtext: "Chill beats to mellow you out.",
      img: "https://cdn.vuetifyjs.com/images/cards/plane.jpg",
    },
  ];
  get navItems() {
    const navItems =
      this.$i18n.locale === "en-US" ? this.navItemsUS : this.navItemsCN;
    const cardNavs: INavItem[] = [];
    for (const [i, nav] of (navItems as INavItem[]).entries()) {
      const newNav: INavItem = {
        ...nav,
        ...(this.items[i] ? this.items[i] : this.items[0]),
        title: nav.text,
      };
      cardNavs.push(newNav);
    }
    return cardNavs;
  }
}
