module.exports = {
  lottery: {
    twoTone: "Two-color ball",
    redball: "Red ball",
    blueball: "Blue ball",
    superBig: "Lottery ticket",
    frontZone: "Front zone",
    backZone: "Back zone",
    getLuckyNumbers: "Get lucky numbers",
    title: "Get your own lucky number",
  },
  subway: {
    moneySpentEachTime: "Money Spent Each Time",
    totalFrequency: "Total Frequency",
    ladder: "Ladder",
    lowerLimit: "Lower Limit",
    upperLimit: "Upper Limit",
    discont: "Discont",
  },
  time: {
    year: "year",
    month: "month",
    day: "day",
  },
  name: "Name",
  add: "Add",
  calculate: "Calculate",
  result: "Result",
  toggles: "Toggles",
  todo: "To be determined",
  setting: {
    theme: "Theme",
    language: "Language",
  },
  menu: "Menu",
};
