



















import { Component, Vue, Prop } from "vue-property-decorator";
import { INavItem } from "../navBar/index.d";
@Component({
  components: {},
})
export default class BottomNav extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly navItemsUS!: INavItem[];
  @Prop({ type: Array, default: () => [] }) readonly navItemsCN!: INavItem[];
  get navItems(): INavItem[] {
    this.navItemsCN;
    const navItems: INavItem[] =
      this.$i18n.locale === "en-US" ? this.navItemsUS : this.navItemsCN;
    return navItems.slice(0, 5);
  }
  value = 0;
  // mounted(){
  //   console.log("Bowen: BottomNav -> mounted -> this.$route.path", this.$route)
  //   console.log("Bowen: BottomNav -> mounted -> index", this.navItems.findIndex(i => i.to === this.$route.path))
  //   this.value = this.navItems.findIndex(i => i.to === this.$route.path);
  // }
  // value = this.navItems[0].name;
  get color() {
    switch (this.value) {
      case 0:
        return "blue-grey";
      case 1:
        return "teal";
      case 2:
        return "pink";
      case 3:
        return "purple darken-2";
      default:
        return "blue-grey lighten-2";
    }
  }
}
