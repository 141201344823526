








































































import NavBar from "@/components/navBar";
import BottomNav from "@/components/bottomNav";
import { Component, Vue, Watch } from "vue-property-decorator";
import { navItemsUS, navItemsCN } from "@/libs/navData";
import { INavItem } from "@/components/navBar/index.d";
@Component({
  components: {
    NavBar,
    BottomNav,
  },
})
export default class App extends Vue {
  private drawerFlag = false;
  private navItemsUS: INavItem[] = navItemsUS;
  private navItemsCN: INavItem[] = navItemsCN;
  // 当屏幕尺寸 大于960 显示侧边栏导航
  @Watch("$vuetify.breakpoint.width", { immediate: true })
  handleWatchWidth(val: number) {
    if (val > 960) {
      this.drawerFlag = true;
    }
  }
  mounted() {
    (window as any).app = this;
  }
}
